<template>
<div style="">
  <div class="page">
    <BreadCrumb />   
    <div class="table_title">
      <p class="first_class_tittle">
        查询服务
      </p>
    </div>
    <!-- 查询条件=============================================================================================================================== -->
    <el-tabs v-model="inquiry_mode" @tab-click="tabs_click">
        <!-- 按表单查询=========================================================================================================================== -->
      <el-tab-pane label="按表单查询" name="first">
        <div class="inquiry_inline">
          <div class="inquiry_inline_box">
            <p>选择数据源：</p> 
            <el-select v-model="dataSource.ds_id" @clear="clear_table_res" @focus="close_all" @change="DataSourceChange(dataSource)" filterable placeholder="请选择数据源">
              <el-option v-for="(item, index) of dataSource" :key="index" :label="item.ds_desc" :value="item.ds_id"> </el-option>
            </el-select>
          </div>
          <div class="inquiry_inline_box">
            <p>选择表：</p>    
            <el-button ref="selected_table_btn" @click="selected_table()">请选择表</el-button>
          </div>
          <!-- <div class="inquiry_inline_box">
            <p>选择列：</p>    
            <el-button ref="selected_column_btn" class="column_buttom"  @click="selected_column()">请选择列</el-button>
          </div> -->
          <div class="inquiry_inline_box">
              <p>时间范围：</p> 
              <el-date-picker 
              v-model="table_timeCond" 
              @focus="close_all"
              @change="time_selected($event)" 
              unlink-panels = true
              type="daterange" 
              end-placeholder="结束日期" 
              range-separator="至" 
              start-placeholder="开始日期"
               />
          </div>
          <div class="table_search">
            <el-button type="primary" @click="table_search(0)">搜索</el-button>
            <el-button type="info" @click="table_empty()">
              <el-icon :size="15">
                <i-refresh/>
              </el-icon>
            </el-button>
             <el-button type="primary"  @click="table_search()" v-show="table_download_show">
                <el-icon :size="15">
                  <i-download/>
                </el-icon>
             </el-button>
          </div>          
        </div>
        <div v-show="table_chackbox" class="table_chackbox" >
          <p class="p_blur">关键字搜索</p>
          <div class="inquiry_inline chaeckbox_inquiry_width">
            <el-input  class="search_input"  :prefix-icon="Search" v-model="table_chackbox_search" placeholder="输入关键字" @input="table_checkbox_search($event)" >
               <template #prefix>
                  <el-icon :size="18">
                  <i-search/>
                </el-icon>                
              </template>
            </el-input>
            
            <!-- <el-button type="info">搜索</el-button> -->
          </div>        
          <el-divider content-position="left">表目录</el-divider>
          <el-checkbox-group v-model="table_checkList"  @change="table_checkbox_callback($event)">
            <el-checkbox  v-for="(item, index) in table_list" :key="index" :label="item.value">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- <div v-if="column_chackbox" class="column_chackbox">
          <p>关键字搜索</p>
          <div class="inquiry_inline chaeckbox_inquiry_width">
            <el-input class="search_input" v-model="table_chackbox_search" placeholder="输入关键字" />
            <el-button type="info">搜索</el-button>
          </div>
          <el-divider content-position="left">列目录</el-divider>
          <el-checkbox-group v-model="column_checkList"  @change="column_checkbox_callback($event)">
            <el-checkbox  v-for="(item, index) in column_list" :key="index" :label="item.value">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div> -->
      </el-tab-pane>
      <!-- 按作业查询=========================================================================================================================== -->
      <el-tab-pane label="按作业查询" name="second">
        <div class="inquiry_inline">
           <div class="inquiry_inline_box">
            <p >选择项目：</p> 
            <el-select v-model="projest.project_id" @clear="clear_project_res" @focus="close_all" @change="ProjestChange(projest)" filterable placeholder="请选择项目">
              <el-option v-for="(item, index) of projest" :key="index" :label="item.project_desc" :value="item.project_id"> </el-option>
            </el-select>
          </div>
          <div class="inquiry_inline_box">
            <p>选择作业：</p>    
            <el-button ref="selected_work_btn" @click="selected_work()">请选择作业</el-button>
          </div>
          <!-- <div class="inquiry_inline_box">
            <p>选择任务：</p>    
            <el-button ref="selected_task_btn" class="column_buttom"  @click="selected_task()">请选择任务</el-button>
          </div> -->
          <div class="inquiry_inline_box">
              <p>时间范围：</p> 
              <el-date-picker 
                v-model="work_timeCond" 
                @focus="close_all"
                @change="work_selected($event)" 
                unlink-panels = true
                type="daterange" 
                end-placeholder="结束日期" 
                range-separator="至" 
                start-placeholder="开始日期"
                />
          </div>
          <div class="work_search">
            <el-button type="primary"  @click="table_search(0)">搜索</el-button>
              <el-button type="info"  @click="work_empty()">
                 <el-icon :size="15">
                    <i-refresh/>
                </el-icon>
              </el-button>
            <el-button type="primary"  @click="table_search()" v-show="work_download_show">
               <el-icon :size="15">
                  <i-download/>
                </el-icon>
            </el-button>
           
          </div>
        </div>
        <div v-if="work_chackbox" class="work_chackbox">
            <p class="p_blur">关键字搜索</p>
            <div class="inquiry_inline chaeckbox_inquiry_width">
              <el-input  class="search_input"  :prefix-icon="Search" v-model="work_chackbox_search" placeholder="输入关键字" @input="work_checkbox_search($event)" />
            </div>        
            <el-divider content-position="left">作业目录</el-divider>
            <el-checkbox-group v-model="work_checkList"  @change="work_checkbox_callback($event)">
              <el-checkbox  v-for="(item, index) in work_list" :key="index" :label="item.value">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- <div v-if="task_chackbox" class="task_chackbox">
            <p>关键字搜索</p>
            <div class="inquiry_inline chaeckbox_inquiry_width">
              <el-input class="search_input" v-model="work_chackbox_search" placeholder="输入关键字" />
              <el-button type="info">搜索</el-button>
            </div>
            <el-divider content-position="left">列目录</el-divider>
            <el-checkbox-group v-model="task_checkList"  @change="task_checkbox_callback($event)">
              <el-checkbox  v-for="(item, index) in task_list" :key="index" :label="item.value">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div> -->
      </el-tab-pane>
    </el-tabs>
    <!-- 查询结果============================================================================================================================= -->
    
    <div class="table_title">
      <p class="first_class_tittle">
        查询结果
      </p>
    </div> 
    <div class="search_message_box" v-if="noquery_show">
      <p>请先进行查询</p>
    </div>
    <div class="search_message_box" v-if="nodata_show">
       <p>未查询到有效数据</p>
    </div>
    <div class="info_res_box" v-show="info_res_show">    
      <div class="res_overview">
        <div class="total_data">  
          <svg-icon icon-class="project" class="total_data_icon"/>
          <div>
            <p>{{total_val}}<span>条</span></p>    
            <p>全部数据</p>
          </div>
        </div>
        <div class="classify_data">
          <div>
            <el-progress type="dashboard" :percentage = qualified_rate >
              <template #default="{ percentage }">
                <span class="percentage-value">{{ percentage }}%</span>
                <span class="percentage-label">优质数据占比</span>
              </template>
            </el-progress>
          </div>
          <div>
            <p>{{qualified_val}}<span>条</span></p>
            <p>优质数据条数</p>
          </div>        
        </div>
        <div class="classify_data">
          <div>
            <el-progress type="dashboard" :percentage = unqualified_rate status="exception">
              <template #default="{ percentage }">
                <span class="percentage-value">{{ percentage }}%</span>
                <span class="percentage-label">劣质数据占比</span>
              </template>
            </el-progress>
          </div>
          <div>
            <p>{{unqualified_val}}<span>条</span></p>
            <p>劣质数据条数</p>
          </div>       
        </div>
      </div>
      <div class="info_table_box">
        <el-table :data="info_table_data" height="360" border style="width: 100%">
          <el-table-column prop="num" label="序号" width="60" align="center"> 
            <template #default="scope" >
              <span class="table_num">{{
                  (currentPageNum - 1) * pageSize + scope.$index + 1
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="project_name" label="项目名称" width="180"> </el-table-column>
          <el-table-column prop="project_desc" label="项目描述" width="180"> </el-table-column>
          <el-table-column prop="table_name" label="表名称" width="200">
            <template #default="scope">
              <div class="table_name_td">
                <p v-for="(item, index) of scope.row.table_name" :key="index" >{{item}}</p>
              </div>             
            </template>
          </el-table-column>
          <!-- <el-table-column prop="work_name" label="作业名称" width="200"> </el-table-column> -->
          <!-- <el-table-column prop="task_name" label="任务名称" width="200"> </el-table-column> -->
          <el-table-column prop="begin_time" label="开始时间" width="200"> </el-table-column>
          <el-table-column prop="end_time" label="结束时间" width="200" align="center"> </el-table-column>
          <el-table-column prop="qualified_val" label="优质数据"> </el-table-column>
          <el-table-column prop="unqualified_val" label="劣质数据" > </el-table-column>
          <!-- 表名 -->
          <!-- 数据源 -->
          <!-- <el-table-column fixed="right" label="操作" width="100" >
            <template #default="scope">
               <el-tooltip
                      class="item"
                      effect="light"
                      content="提交工单"
                      placement="top-end"
                  >
                    <el-button
                        type="primary"
                        circle
                        size="mini"
                        @click="submit(scope.row)"
                    >
                      <el-icon :size="15">
                        <i-delete/>
                      </el-icon>
                    </el-button>
                  </el-tooltip>
            
            </template>
          </el-table-column> -->
        </el-table>
      </div> 
      <div class="pagination_box">
        <el-pagination
          v-model:currentPage="currentPageNum"
          background
          layout="total, prev, pager, next, jumper"
          :page-size="pageSize"
          :total="totalIndex"
          @current-change="handleCurrentChange"
        />  
      </div>   
    </div>  
    
  </div>
  </div> 
</template>

<script>
import BreadCrumb from '/src/components/BreadCrumb'
import { mapGetters } from 'vuex'
export default {
  name: 'InfoQueryService',
  components: {
    BreadCrumb,
  },
  data() {
    return {
      //表、作业模糊查询input
      work_chackbox_search:"",
      table_chackbox_search:"",
      //表单查询数据导出按钮
      table_download_show:false,
      //表单查询数据导出按钮
      work_download_show:false,
      //优质数据比率
      qualified_rate:"",
      //劣质数据比率
      unqualified_rate:"",
      //全部数据
      total_val:"",
      //劣质数据
      unqualified_val:"",
      //优质数据
      qualified_val:"",
      //是否查询，是否查到数据
      noquery_show:true,
      nodata_show:false,
      //控制查询结果显示
      info_res_show:false,
      //当前第几页
      currentPageNum:1,
      //每页条数
      pageSize:5,
      //总条数
      totalIndex:3,   
      //table数据
      info_table_data:[],
      //表和列checkbox的数据
      table_list:[],
      //作业和任务checkbox的数据
      work_list:[],
      task_list:[],
      // table_list:[{value:1,name:'表1'},{value:2,name:'表2'},{value:3,name:'表3'}],
      column_list:[],
      //表和列checkbox的选中状态
      table_checkList: [],
      column_checkList:[],
      //表和列checkbox的选中内容回调
      table_checked:[],
      column_checked:[],
      //作业和任务checkbox的选中状态
      work_checkList: [],
      task_checkList:[],
      //作业和任务checkbox的选中内容回调
      work_checked:[],
      task_checked:[],
      //控制表和列checkbox显示
      table_chackbox:false,
      column_chackbox:false,
      //控制作业和任务checkbox显示
      work_chackbox:false,
      task_chackbox:false,
      //按钮点击后文字
      selected_table_btn:"已选表单：",
      selected_column_btn:"已选列：",
      selected_work_btn:"已选作业：",
      selected_task_btn:"已选任务：",
      //tabs开始显示哪页
      inquiry_mode: 'first',
      //数据源
      dataSource: [],
      //项目
      projest:[],
      //选中的数据源
      table_selected_dsid : null,
      //选中的项目
      table_selected_proid : null,
      //checkbox选中的table
      table_id_list : [],
      column_id_list : [],
      work_id_list : [],
      task_id_list : [],
      //按表单查询时间
      table_timeCond: null,
      work_timeCond: null,
      //转换后时间
      table_time : [],
      work_time : [],
      type: 0
    }
  },
  computed: {
    ...mapGetters(['pageSize', 'user']),
  },
  mounted: function () {
    this.getAllDataSource()
    this.getAllProject()
  },
  methods: {
    //作业checkbox模糊查询==============================================================================================================
    work_checkbox_search(event){
        console.log(event)
        this.$axios.GET_SER('/api/v1/core/externalService/getProjectInWork', { projectId: this.table_selected_proid,workName:event }).then(res => {
          this.work_list=[];
          res.data.forEach(element => {
            const work_res_map = {value:'',name:''}
            work_res_map.value = element.work_id;
            work_res_map.name = element.work_name;
            this.work_list.push(work_res_map);
         });       
      })
    },
    //表checkbox模糊查询========================================================================================================================
    table_checkbox_search(event){
      console.log(this.table_selected_dsid)
      console.log(event)
       this.$axios.GET_SER('/api/v1/core/externalService/getTableInDsId', { dsId:this.table_selected_dsid,tableName:event}).then(res => {
         this.table_list=[];
         
         console.log(res)
         res.data.forEach(element => {
           const table_res_map = {value:'',name:''}
           table_res_map.value = element.tab_id;
           table_res_map.name = element.tab_desc;
           this.table_list.push(table_res_map);
         });       
      })
    },
    //按表、作业查询的清空按钮============================================================================================================
    clear_table_res(){
      this.dataSource.ds_id = null
    },
    clear_project_res(){
      this.projest.project_id = null
    },
    table_empty(){
       this.clear_table_res()
       this.table_timeCond = null
       this.table_checkList = []
       this.table_list = []
       this.$refs.selected_table_btn.$el.innerText = "请选择表"
       this.table_download_show = false
       this.table_checked = []
    },
    work_empty(){
      this.clear_project_res()
      this.work_timeCond = null
      this.work_checkList = []
      this.work_list = []
      this.$refs.selected_work_btn.$el.innerText = "请选择表"
      this.work_download_show = false
      this.work_checked = []
    },
    //按作业查询time选择change回调转换时间格式=====================================================================
    work_selected(sel_time){
      this.work_timeCond = sel_time;
      this.work_time =[];
			for (var item of this.work_timeCond) {
				//object 说明是中国标准时间 （未转换）
				this.work_time.push(item.getFullYear() + '-' + (item.getMonth() + 1) + '-' + item.getDate() + ' ' + item.getHours() + ':' + item.getMinutes() + ':' + item.getSeconds());
			}
    },
    // //选择任务目录按钮=================================================================================
    // selected_task(){
    //   this.task_chackbox = ! this.task_chackbox;
    //    if(this.work_chackbox == true){
    //        this.work_chackbox = ! this.work_chackbox; 
    //    }     
    //     this.$refs.selected_task_btn.$el.innerText = this.selected_task_btn + this.task_checked.length +"个"; 
    // },
    //任务checkbox勾选回调====================================================================================
    // task_checkbox_callback(event){
    //   this.task_checked = event;
    //  this.$refs.selected_task_btn.$el.innerText = this.selected_task_btn + this.task_checked.length +"个";
    //   this.task_id_list = []
    //   const task_key_list = []
    //   $.each(this.task_checkList,(key,value)=>{         
    //      this.task_id_list.push(value)
    //      task_key_list.push(key)     
    //   })
    // },
    //作业checkbox勾选回调=============================================================================================
    work_checkbox_callback(event){
      this.work_checked = event;
      this.$refs.selected_work_btn.$el.innerText = this.selected_work_btn + this.work_checked.length +"个";
      this.work_id_list = []
      const work_key_list = []
      $.each(this.work_checkList,(key,value)=>{         
         this.work_id_list.push(value)
         work_key_list.push(key)     
      })
      //根据workId获取Task值
      this.WorkChange(this.work_id_list)
    },
    //根据workId获取Task值=================================================================================
    // WorkChange(item) {
    //     this.$axios.GET_SER('/api/v1/core/externalService/getWorkInTask', { workIds:item }).then(res => {
    //       if (res.code == 200) {
    //         this.task_list = [];
    //         res.data.forEach(e=>{
    //           const task_res_map = {value:'',name:''};
    //           task_res_map.value = e.id;
    //           task_res_map.name = e.task_name;
    //           this.task_list.push(task_res_map)
    //         })
    //       }
    //     })
    // },
    //选择作业目录按钮=================================================================================
    selected_work(){
      this.work_chackbox = ! this.work_chackbox; 
      if( this.task_chackbox == true){
        this.task_chackbox = ! this.task_chackbox;
      }
      this.$refs.selected_work_btn.$el.innerText = this.selected_work_btn + this.work_checked.length +"个";
    },
    //根据projectId获取Work值=================================================================================
    ProjestChange(item){
      this.table_selected_proid = item.project_id;
        this.$axios.GET_SER('/api/v1/core/externalService/getProjectInWork', { projectId: item.project_id }).then(res => {
          this.work_list=[];
          res.data.forEach(element => {
            const work_res_map = {value:'',name:''}
            work_res_map.value = element.work_id;
            work_res_map.name = element.work_name;
            this.work_list.push(work_res_map);
         });       
      })
    },
    //按表查询time选择change回调转换时间格式=======================================================================================================
    time_selected(sel_time){
      this.table_timeCond = sel_time;
      this.table_time =[];
			for (var item of this.table_timeCond) {
				//object 说明是中国标准时间 （未转换）
				this.table_time.push(item.getFullYear() + '-' + (item.getMonth() + 1) + '-' + item.getDate() + ' ' + item.getHours() + ':' + item.getMinutes() + ':' + item.getSeconds());
			}
    },
    //提交工单=============================================================================================================================
    submit(row){
      console.log(row)
    },
    //分页跳转改变回调=====================================================================================================================
    handleCurrentChange(){
      if(this.type == 0){
        this.table_search(
          this.currentPageNum-1
        )
      }else if(this.type == 1){
        this.table_search(
          this.currentPageNum-1
        )
      }
    },
    //关闭表和列选择========================================================================================================================
    close_all(){
       if( this.column_chackbox == true){
          this.column_chackbox = ! this.column_chackbox;
      }
        if(this.table_chackbox == true){
          this.table_chackbox = ! this.table_chackbox; 
       }    
        if(this.work_chackbox == true){
          this.work_chackbox = ! this.work_chackbox; 
       }     
        if(this.task_chackbox == true){
          this.task_chackbox = ! this.task_chackbox; 
       }      
    },
    //按表单查询搜索按钮=====================================================================================================================
    table_search(page){
      this.close_all() 
      const search_startTime = this.table_time[0];
      const search_endTime = this.table_time[1];
      let table_id_list = Object.values(this.table_id_list)
      const column_id_list = Object.values(this.column_id_list)
      const work_startTime = this.work_time[0];
      const work_endTime = this.work_time[1];
      const work_id_list = Object.values(this.work_id_list)
      const task_id_list = Object.values(this.task_id_list)
      const type = this.type;
      console.log(this.table_id_list)
      console.log(table_id_list)
      var table_map = {
        type:type,
        dsId:this.table_selected_dsid,
        tabIds:table_id_list,
        colIds:column_id_list,
        projectId:this.table_selected_proid,
        workIds:work_id_list,
        taskIds:task_id_list,
        startTime:search_startTime,
        endTime: search_endTime,
        page: page,
        pageSize: 5
      }
      var data_map = {
        type:type,
        dsId:this.table_selected_dsid,
        tabIds:table_id_list,
        colIds:column_id_list,
        projectId:this.table_selected_proid,
        workIds:work_id_list,
        taskIds:task_id_list,
        startTime:search_startTime,
        endTime: search_endTime,
      }
      if(type == 1){
        table_map.startTime = work_startTime;
        data_map.startTime = work_startTime;
        table_map.endTime = work_endTime;
        data_map.endTime = work_endTime;
      }
      console.log(data_map);
      console.log(table_map);
      if(page == null){
        this.$axios.DOWNLOAD('/api/v1/core/externalService/downloadDataQualityIssues', data_map)
        .then(res=>{
          this.$publics.downloadFile(res, '质量问题', 'xls')
        })
      }else{
        this.$axios.GET_SER('/api/v1/core/externalService/getDataQualityIssues',table_map)
        .then(res=>{
          console.log(res)
          if(res.data.list.length != 0){
            this.noquery_show =false;
            this.info_res_show = true;
            this.nodata_show = false;
            this.table_download_show = true;
            this.work_download_show = true;
            if(page==0){
              this.currentPageNum = 1
            }
            this.totalIndex = res.data.total
            this.info_table_data = res.data.list
          }else{
            this.noquery_show =false,
            this.nodata_show = true,  
            this.info_res_show = false;
          }       
      })
       this.$axios.GET_SER('/api/v1/core/externalService/dataQualityStatistics', data_map)
      .then(res=>{
        this.total_val = res.data.sum_val
        this.unqualified_val = res.data.unqualified_val
        this.qualified_val = res.data.qualified_val
        this.qualified_rate = parseFloat(res.data.qualified_val/res.data.sum_val*100).toFixed(2)
        this.unqualified_rate = parseFloat(res.data.unqualified_val/res.data.sum_val*100).toFixed(2)
      })
      }
    },
    //表checkbox勾选回调=====================================================================================================================
    table_checkbox_callback(event){
      this.table_checked = event;
      this.$refs.selected_table_btn.$el.innerText = this.selected_table_btn + this.table_checked.length +"个";
      this.table_id_list = []
      const table_key_list = []
      $.each(this.table_checkList,(key,value)=>{         
         this.table_id_list.push(value)
         table_key_list.push(key)     
      })
      //根据tableid获取column值
      // this.TableNameChange(this.table_id_list)
    },
     //列checkbox勾选回调=====================================================================================================================
    // column_checkbox_callback(event){
    //  this.column_checked = event;
    //  this.$refs.selected_column_btn.$el.innerText = this.selected_column_btn + this.column_checked.length +"个";
    //   this.column_id_list = []
    //   const table_key_list = []
    //   $.each(this.column_checkList,(key,value)=>{         
    //      this.column_id_list.push(value)
    //      table_key_list.push(key)     
    //   })
    // },
    //tabs切换回调=====================================================================================================================
     tabs_click(tab) {
       if(tab.props.name == "second"){
         this.type = 1;
       }else if(tab.props.name == "first"){
         this.type = 0;
       }
    },
    //选择表目录按钮=====================================================================================================================
    selected_table(){     
      this.table_chackbox = ! this.table_chackbox; 
      // this.$refs.table_checkbox_focus.focus();
      if( this.column_chackbox == true){
        this.column_chackbox = ! this.column_chackbox;
      }
      this.$refs.selected_table_btn.$el.innerText = this.selected_table_btn + this.table_checked.length +"个";
    },
    //选择列目录按钮=====================================================================================================================
    // selected_column(){
    //    this.column_chackbox = ! this.column_chackbox;
    //    if(this.table_chackbox == true){
    //        this.table_chackbox = ! this.table_chackbox; 
    //    }     
    //     this.$refs.selected_column_btn.$el.innerText = this.selected_column_btn + this.column_checked.length +"个";      
    // },
    //根据tableid获取column值======================================================================================================================
    // TableNameChange(item) {
    //     this.$axios.GET_SER('/api/v1/core/externalService/getColInTabId', { tabIds:item }).then(res => {
    //       if (res.code == 200) {
    //         this.column_list = [];
    //         res.data.forEach(e=>{
    //           const column_res_map = {value:'',name:''};
    //           column_res_map.value = e.col_id;
    //           column_res_map.name = e.col_name;
    //           this.column_list.push(column_res_map)
    //         })
    //       }
    //     })
    // },
    //根据数据源获取table的值===============================================================================================================================
    DataSourceChange(item) {
      this.table_selected_dsid = item.ds_id;
      this.$axios.GET_SER('/api/v1/core/externalService/getTableInDsId', { dsId: item.ds_id }).then(res => {
         this.table_list=[];
         res.data.forEach(element => {
           const table_res_map = {value:'',name:''}
           table_res_map.value = element.tab_id;
           table_res_map.name = element.tab_desc;
           this.table_list.push(table_res_map);
         });       
      })
    },
    //mounted时获取数据源===================================================================================================================================
    getAllDataSource() {
      this.$axios.GET('/api/v1/core/externalService/getAllDataSource', {}).then(res => {
        if (res.code == 200) {
          this.dataSource = res.data
        }
      })
    },
    //mounted时获取项目===========================================================================================================================================
    getAllProject() {
      this.$axios.GET('/api/v1/core/externalService/getAllProject', {}).then(res => {
        if (res.code == 200) {
          this.projest = res.data
        }
      })
    },
  },
}
</script>

<style scoped>
.table_name_td{
  display: flex;
  flex-direction: column;
}
.table_name_td>p{
  background-color: #409eff21;
  /* color: #ffffff; */
  border-radius:3px ;
  padding: 0px 5px;
  margin-top: 5px;
}
.table_name_td>p:nth-child(1){
  margin: top 0px !important;;
}
.el-input>>>.el-input__prefix{
  left: 9px;
  top: 5px;
}
.table_num{
  width: 100%;
  text-align: center;
}
.search_message_box{
  width: 100%;
  text-align: center;
}
.search_message_box>p{
  font-size: 16px;
  line-height: 50px;
  color: #909399;
  border: 1px solid #90939930;
  border-radius: 5px;
  margin: 20px 10px;
}
.el-checkbox-group {
    font-size: 0;
    max-height: 300px;
    overflow: auto;
}
.table_title{
  margin-top: 10px;
}
.pagination_box{
  margin-bottom: 20px;
}
.info_table_box{
  margin: 30px 0px 10px 0px;
}
.info_res_box{
  margin-top: 10px;
}
.chaeckbox_inquiry_width{
  width: 80%;
}
.total_data_icon{
  font-size: 90px;
  color: #9093998c;
}
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}

.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 12px;
}
.res_overview{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.res_overview>.total_data{
  display: flex;
  width: 30%;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background-color: #bad1ff29;
  border-radius: 5px;
}
.res_overview>.total_data>div{
  width: 50%;
}
.res_overview>.total_data>div>p:nth-child(1){
text-align: center;
  font-size: 40px;
  color: #909399;
  padding-top: 15px;
}
.res_overview>.total_data>div>p:nth-child(1)>span{
  font-size: 14px;
}
.res_overview>.total_data>div>p:nth-child(2){
  text-align: center;
  font-size: 18px;
  line-height: 30px;
   color: #909399;
}
.res_overview>.classify_data{
  display: flex;
  justify-content: center;
  width: 30%;
   box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
  align-items: center;
  padding:15px 40px 0px 40px;
}
.classify_data>div:nth-child(2){
  width:100% ;
}
.classify_data>div:nth-child(2)>p:nth-child(1){
  text-align: center;
  font-size: 40px;
  color: #909399;
}
.classify_data>div:nth-child(2)>p:nth-child(1)>span{
  font-size: 14px;
}
.classify_data>div:nth-child(2)>p:nth-child(2){
  text-align: center;
  font-size: 18px;
  line-height: 30px;
   color: #909399;
}
.table_search{
  margin-top: 30px;
  margin-right: 20px;
}
.search_input{
  margin-right: 20px;
}
.inquiry_inline{
  display: flex;
  justify-content: space-between;
}
.inquiry_inline>.inquiry_inline_box{
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  width: 25%;
}
.inquiry_inline>.inquiry_inline_box>p{
  color: #909399;
  font-size: 14px;
  line-height: 30px;
}
.inquiry_inline>.inquiry_inline_box>button{
  width:100%;
  color:#C0C4CC;
}
.table_chackbox{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 10px 20px;
  position: relative;
  z-index: 200;
}
.table_chackbox>p{
    color: #909399;
  font-size: 14px;
  line-height: 30px;
}
.table_chackbox::after{
  content: "";
   width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #C0C4CC85;
    position:absolute;
    top: -15px;
    left: 500px;
}
.column_chackbox{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 10px 20px;
  position: relative;
}
.column_chackbox>p{
    color: #909399;
  font-size: 14px;
  line-height: 30px;
}
.column_chackbox::after{
  content: "";
   width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #C0C4CC85;
    position:absolute;
    top: -15px;
    left: 585px;
}
.el-checkbox-group>label{
  margin-bottom: 0px;
  
}
.work_search{
  margin-top: 30px;
  margin-right: 20px;
}
.work_chackbox{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 10px 20px;
  position: relative;
}
.work_chackbox>p{
    color: #909399;
  font-size: 14px;
  line-height: 30px;
}
.work_chackbox::after{
  content: "";
   width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #C0C4CC85;
    position:absolute;
    top: -15px;
    left: 500px;
}
.task_chackbox{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 10px 20px;
  position: relative;
}
.task_chackbox>p{
    color: #909399;
  font-size: 14px;
  line-height: 30px;
}
.task_chackbox::after{
  content: "";
   width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #C0C4CC85;
    position:absolute;
    top: -15px;
    left: 585px;
}
</style>
